import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../store/context';
import { CaseStudyData } from '../../mock/SiteData';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '../../hooks/useTriggerLightTheme';
import NotFoundPage from '../404';

const caseStudy = CaseStudyData.find((m) => m.id === 'financia-click');

const FinanciaClick = () => {
  const { navbarHeight, setCurrentPage } = useContext(AppContext);
  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  const animationOffset = navbarHeight / 2 + 10;
  useTriggerLightTheme(projectWhiteSectionRef.current, animationOffset, animationOffset);

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }
  return <></>;
};

export default FinanciaClick;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO
        title="Loop3 Studio - FinanciaClick"
        description={`${caseStudy.hero.heroDescriptionText}`}
      />
    );
  }
};
